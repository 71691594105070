import { ConclusionAppealQuery } from "generated/operations";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { TConclusionDoctorCreateResult } from "pages/AppealPage/utils/types";

export const conclusionDoctorValidateCreate = (
  isNarcology: boolean,
  isPsychiatris: boolean,
  data: ConclusionAppealQuery | undefined
): TConclusionDoctorCreateResult => {
  if (data === undefined || data.conclusionAppeals?.totalCount === 0)
    return {
      isCreateConclusion: true,
      isNarcologyAvailable: isNarcology,
      isPsychiatryAvailable: isPsychiatris,
      isClinicalPsychologistAvailable: false,
    };

  let narcologyExists: boolean = false;
  let psychiatristExists: boolean = false;

  const conclusions = data.conclusionAppeals?.items!;
  for (let index = 0; index < conclusions.length; index++) {
    const conclusion = conclusions[index];
    if (conclusion.name === ConclusionsName.Narcology && conclusion.isActual)
      narcologyExists = true;
    if (conclusion.name === ConclusionsName.Psychiatrist && conclusion.isActual)
      psychiatristExists = true;
  }

  return {
    isCreateConclusion:
      (isNarcology && !narcologyExists) ||
      (isPsychiatris && !psychiatristExists),
    isNarcologyAvailable: isNarcology && !narcologyExists,
    isPsychiatryAvailable: isPsychiatris && !psychiatristExists,
    isClinicalPsychologistAvailable: false,
  };
};
