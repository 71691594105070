import { Button, Grid } from "@genome-frontend/uikit/build/lib/uikit";
import { useSubmitWithCspHandler } from "utils/useSubmitWithCspHandler";
import { useMakeClinicalPsychologistAppointmentMutation } from "generated/hooks";
import { locale } from "locale";
import { useToasts } from "components/Toast";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { AppealOpoClinicalPsychologistAppointmentQuery } from "generated/operations";
import { ApolloQueryResult } from "@apollo/client";

interface Props {
  appealId: number;
  appealData: AppealOpoClinicalPsychologistAppointmentQuery | undefined;
  loading: boolean;
  refetch(): Promise<
    ApolloQueryResult<AppealOpoClinicalPsychologistAppointmentQuery>
  >;
}

export const ClinicalPsychologistAppointmentButton = (props: Props) => {
  const { addSuccessToast, addErrorsToast } = useToasts();

  const [mutate] = useMakeClinicalPsychologistAppointmentMutation();

  const makeAppointment = async () => {
    const { data } = await mutate({
      variables: {
        input: { id: props.appealId },
      },
    });

    const errs = data?.makeClinicalPsychologistAppointment.errors;

    if (errs) {
      return addErrorsToast(errs);
    } else {
      addSuccessToast(locale.appealOpo.clinicalPsychologistAppointment);
      props.refetch();
    }
  };

  const { submitWithCspHandler } = useSubmitWithCspHandler({
    next: makeAppointment,
  });

  const handleClick = async () => {
    await submitWithCspHandler(null);
  };

  return (
    <>
      <Grid container sx={{ mt: 1 }}>
        <Grid xs={6} item>
          {props.loading ? (
            <CenteredCircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={handleClick}
              disabled={
                props.appealData?.appealOpoById.clinicalPsychologistAppointment
              }
            >
              {"Назначить осмотр у клинического психолога"}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
