import { ConclusionAppealQuery } from "generated/operations";
import { numberOfPsychiatristsForOpo } from "pages/AppealPage/utils/conclusionConsts";
import { ConclusionsName } from "pages/AppealPage/utils/conclusionsName";
import { TConclusionDoctorCreateResult } from "pages/AppealPage/utils/types";

export const opoConclusionDoctorValidateCreate = (
  employeeId: string,
  isPsychiatrist: boolean,
  isClinicalPsychologist: boolean,
  data: ConclusionAppealQuery | undefined
): TConclusionDoctorCreateResult => {
  if (data === undefined || data.conclusionAppeals?.totalCount === 0) {
    return {
      isCreateConclusion: true,
      isNarcologyAvailable: false,
      isPsychiatryAvailable: isPsychiatrist,
      isClinicalPsychologistAvailable: isClinicalPsychologist,
    };
  }

  let psychiatristsCount: number = 0;
  let currentPsychiatristExists: boolean = false;
  let clinicalPsychologistExists: boolean = false;

  for (let conclusion of data.conclusionAppeals?.items!) {
    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.doctorId === employeeId &&
      conclusion.isActual
    ) {
      currentPsychiatristExists = true;
    }
    if (
      conclusion.name === ConclusionsName.Psychiatrist &&
      conclusion.isActual
    ) {
      psychiatristsCount++;
      continue;
    }
    if (
      conclusion.name === ConclusionsName.ClinicalPsychologist &&
      conclusion.isActual
    ) {
      clinicalPsychologistExists = true;
    }
  }

  return {
    isCreateConclusion:
      (isPsychiatrist &&
        psychiatristsCount < numberOfPsychiatristsForOpo &&
        !currentPsychiatristExists) ||
      (isClinicalPsychologist && !clinicalPsychologistExists),
    isNarcologyAvailable: false,
    isPsychiatryAvailable:
      isPsychiatrist &&
      psychiatristsCount < numberOfPsychiatristsForOpo &&
      !currentPsychiatristExists,
    isClinicalPsychologistAvailable:
      isClinicalPsychologist && !clinicalPsychologistExists,
  };
};
