// THIS FILE IS AUTOGENERATED. PLEASE DO NOT EDIT IT
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  UUID: any;
};

/** Адрес пользователя */
export type AddressOutputFilterInput = {
  and?: InputMaybe<Array<AddressOutputFilterInput>>;
  /** Дата конца регистрации */
  endDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Полный адрес */
  fullAddress?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор адреса */
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<AddressOutputFilterInput>>;
  /** Дата регистрации */
  startDate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
};

/** Адрес пользователя */
export type AddressSortInput = {
  /** Дата конца регистрации */
  endDate?: InputMaybe<SortEnumType>;
  /** Полный адрес */
  fullAddress?: InputMaybe<SortEnumType>;
  /** Идентификатор адреса */
  id?: InputMaybe<SortEnumType>;
  /** Дата регистрации */
  startDate?: InputMaybe<SortEnumType>;
};

/** Выходная сущность по услуги с зависимыми документами */
export type AppealEntityOutputFilterInput = {
  and?: InputMaybe<Array<AppealEntityOutputFilterInput>>;
  /** Список документов по услуги */
  documents?: InputMaybe<ListFilterInputTypeOfConclusionAppealOutputFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование услуги */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppealEntityOutputFilterInput>>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Текущий статус обращения */
  status?: InputMaybe<AppealStatusOperationFilterInput>;
};

/** Выходная сущность по услуги с зависимыми документами */
export type AppealEntitySortInput = {
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование услуги */
  name?: InputMaybe<SortEnumType>;
  /** Id пациента */
  patientId?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
  /** Текущий статус обращения */
  status?: InputMaybe<SortEnumType>;
};

/** Выходная сущность по услуге ОПО */
export type AppealOpoEntityOutputFilterInput = {
  /** Дано информационное добровольное согласие? (ИДС) */
  agreement?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<AppealOpoEntityOutputFilterInput>>;
  /** Назначен прием клинического психолога? */
  clinicalPsychologistAppointment?: InputMaybe<BooleanOperationFilterInput>;
  /** Размер врачебной комиссии */
  commissionSize?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  /** Структурное подразделение */
  department?: InputMaybe<StringOperationFilterInput>;
  /** Список документов по услуги */
  documents?: InputMaybe<ListFilterInputTypeOfConclusionAppealOutputFilterInput>;
  /** Должность сотрудника */
  employeePosition?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Возможность отменить заявку */
  isAbleToCancel?: InputMaybe<BooleanOperationFilterInput>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование услуги */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор ОКВЭД */
  okvedId?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Идентификатор вида деятельности из приложения к приказу 342н */
  opoWorkTypeId?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<AppealOpoEntityOutputFilterInput>>;
  /** Идентифиатор медицинской организации */
  organizationInfoId?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Текущий статус обращения */
  status?: InputMaybe<AppealStatusOperationFilterInput>;
};

/** Выходная сущность по услуге ОПО */
export type AppealOpoEntitySortInput = {
  /** Дано информационное добровольное согласие? (ИДС) */
  agreement?: InputMaybe<SortEnumType>;
  /** Назначен прием клинического психолога? */
  clinicalPsychologistAppointment?: InputMaybe<SortEnumType>;
  /** Размер врачебной комиссии */
  commissionSize?: InputMaybe<SortEnumType>;
  /** Структурное подразделение */
  department?: InputMaybe<SortEnumType>;
  /** Должность сотрудника */
  employeePosition?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Возможность отменить заявку */
  isAbleToCancel?: InputMaybe<SortEnumType>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование услуги */
  name?: InputMaybe<SortEnumType>;
  /** Идентификатор ОКВЭД */
  okvedId?: InputMaybe<SortEnumType>;
  /** Идентификатор вида деятельности из приложения к приказу 342н */
  opoWorkTypeId?: InputMaybe<SortEnumType>;
  /** Идентифиатор медицинской организации */
  organizationInfoId?: InputMaybe<SortEnumType>;
  /** Id пациента */
  patientId?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
  /** Текущий статус обращения */
  status?: InputMaybe<SortEnumType>;
};

export type AppealOpoTemplateOutputFilterInput = {
  and?: InputMaybe<Array<AppealOpoTemplateOutputFilterInput>>;
  /** Id шаблона */
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Название шаблона */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppealOpoTemplateOutputFilterInput>>;
  /** Идентификатор файла шаблона */
  templateFileId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AppealOpoTemplateSortInput = {
  /** Id шаблона */
  id?: InputMaybe<SortEnumType>;
  /** Название шаблона */
  name?: InputMaybe<SortEnumType>;
  /** Идентификатор файла шаблона */
  templateFileId?: InputMaybe<SortEnumType>;
};

/** Выходная сущность по услуги */
export type AppealOutputFilterInput = {
  and?: InputMaybe<Array<AppealOutputFilterInput>>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование услуги */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppealOutputFilterInput>>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Текущий статус обращения */
  status?: InputMaybe<AppealStatusOperationFilterInput>;
};

/** Выходная сущность по услуги */
export type AppealSortInput = {
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование услуги */
  name?: InputMaybe<SortEnumType>;
  /** Id пациента */
  patientId?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
  /** Текущий статус обращения */
  status?: InputMaybe<SortEnumType>;
};

/** Статус обращения */
export enum AppealStatus {
  /** Закрыто */
  COMPLETED = "COMPLETED",
  /** Создано */
  CREATED = "CREATED",
}

export type AppealStatusOperationFilterInput = {
  eq?: InputMaybe<AppealStatus>;
  in?: InputMaybe<Array<AppealStatus>>;
  neq?: InputMaybe<AppealStatus>;
  nin?: InputMaybe<Array<AppealStatus>>;
};

/** Тип услуги */
export type AppealTypeOutputFilterInput = {
  and?: InputMaybe<Array<AppealTypeOutputFilterInput>>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppealTypeOutputFilterInput>>;
  /** Краткое наименование */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

/** Тип услуги */
export type AppealTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  /** Наименование */
  name?: InputMaybe<SortEnumType>;
  /** Краткое наименование */
  shortName?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = "AFTER_RESOLVER",
  BEFORE_RESOLVER = "BEFORE_RESOLVER",
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars["Boolean"]>;
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientOutputFilterInput = {
  /** Адреса пользователя */
  addresses?: InputMaybe<ListFilterInputTypeOfAddressOutputFilterInput>;
  and?: InputMaybe<Array<ClientOutputFilterInput>>;
  /** Дата рождения */
  birthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Отчество */
  fathersName?: InputMaybe<StringOperationFilterInput>;
  /** ФИО */
  fio?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор пользователя */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Имя */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientOutputFilterInput>>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Пол пользователя */
  sex?: InputMaybe<SexOperationFilterInput>;
  /** СНИЛС */
  snils?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия */
  surname?: InputMaybe<StringOperationFilterInput>;
};

export type ClientSortInput = {
  /** Дата рождения */
  birthdate?: InputMaybe<SortEnumType>;
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** Отчество */
  fathersName?: InputMaybe<SortEnumType>;
  /** ФИО */
  fio?: InputMaybe<SortEnumType>;
  /** Идентификатор пользователя */
  id?: InputMaybe<SortEnumType>;
  /** Имя */
  name?: InputMaybe<SortEnumType>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Пол пользователя */
  sex?: InputMaybe<SortEnumType>;
  /** СНИЛС */
  snils?: InputMaybe<SortEnumType>;
  /** Фамилия */
  surname?: InputMaybe<SortEnumType>;
};

export type ComparableDateOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<Scalars["Date"]>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<Scalars["Date"]>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<Scalars["UUID"]>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<Scalars["UUID"]>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<Scalars["Int"]>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

export type ComparableNullableOfDateOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

export type ComparableTimeOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["TimeSpan"]>;
  gt?: InputMaybe<Scalars["TimeSpan"]>;
  gte?: InputMaybe<Scalars["TimeSpan"]>;
  in?: InputMaybe<Array<Scalars["TimeSpan"]>>;
  lt?: InputMaybe<Scalars["TimeSpan"]>;
  lte?: InputMaybe<Scalars["TimeSpan"]>;
  neq?: InputMaybe<Scalars["TimeSpan"]>;
  ngt?: InputMaybe<Scalars["TimeSpan"]>;
  ngte?: InputMaybe<Scalars["TimeSpan"]>;
  nin?: InputMaybe<Array<Scalars["TimeSpan"]>>;
  nlt?: InputMaybe<Scalars["TimeSpan"]>;
  nlte?: InputMaybe<Scalars["TimeSpan"]>;
};

/** Сущность представляющая или заключение врача/терапевта или результат услуги */
export type ConclusionAppealOutputFilterInput = {
  and?: InputMaybe<Array<ConclusionAppealOutputFilterInput>>;
  /** Файл заключения */
  conclusionFile?: InputMaybe<FileDtoFilterInput>;
  /** Тип заключения (врача/терапевта) */
  conclusionType?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Id сотрудника врача */
  doctorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Номер заключения (врача/терапевта) */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConclusionAppealOutputFilterInput>>;
  /** Идентификатор родительского сущности Appeal */
  parentId?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Дата окончания ограничения (только для терапевта) */
  restrictionDateEnd?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Временное ограничение по разрешению (только для терапевта) */
  restrictionType?: InputMaybe<NullableOfRestrictionTypeOperationFilterInput>;
  /** Тип сущности */
  type?: InputMaybe<ConclusionAppealTypeOperationFilterInput>;
};

/** Сущность представляющая или заключение врача/терапевта или результат услуги */
export type ConclusionAppealSortInput = {
  /** Файл заключения */
  conclusionFile?: InputMaybe<FileDtoSortInput>;
  /** Тип заключения (врача/терапевта) */
  conclusionType?: InputMaybe<SortEnumType>;
  /** Id сотрудника врача */
  doctorId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование */
  name?: InputMaybe<SortEnumType>;
  /** Номер заключения (врача/терапевта) */
  number?: InputMaybe<SortEnumType>;
  /** Идентификатор родительского сущности Appeal */
  parentId?: InputMaybe<SortEnumType>;
  /** Id пациента */
  patientId?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
  /** Дата окончания ограничения (только для терапевта) */
  restrictionDateEnd?: InputMaybe<SortEnumType>;
  /** Временное ограничение по разрешению (только для терапевта) */
  restrictionType?: InputMaybe<SortEnumType>;
  /** Тип сущности */
  type?: InputMaybe<SortEnumType>;
};

/** Тип данных базовой сущности для заключение врача/терапевта или результат услуги */
export enum ConclusionAppealType {
  /** Результат услуги */
  APPEAL_RESULT = "APPEAL_RESULT",
  /** Заключение терапевта */
  CONCLUSION_COMMISSION_HEAD = "CONCLUSION_COMMISSION_HEAD",
  /** Заключение врача */
  CONCLUSION_DOCTOR = "CONCLUSION_DOCTOR",
  /** Заключение терапевта */
  CONCLUSION_THERAPIST = "CONCLUSION_THERAPIST",
}

export type ConclusionAppealTypeOperationFilterInput = {
  eq?: InputMaybe<ConclusionAppealType>;
  in?: InputMaybe<Array<ConclusionAppealType>>;
  neq?: InputMaybe<ConclusionAppealType>;
  nin?: InputMaybe<Array<ConclusionAppealType>>;
};

/** Выходная структура по истории загрузки файлов заключений */
export type ConclusionFileHistoryOutputFilterInput = {
  and?: InputMaybe<Array<ConclusionFileHistoryOutputFilterInput>>;
  /** Наименование услуги/обращения */
  appealType?: InputMaybe<StringOperationFilterInput>;
  /** ФИО клиента */
  clientFio?: InputMaybe<StringOperationFilterInput>;
  /** Дата выдачи заключения */
  conclusionDate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** Наименование заключения */
  conclusionName?: InputMaybe<StringOperationFilterInput>;
  /** Номер заключения */
  conclusionNumber?: InputMaybe<StringOperationFilterInput>;
  /** Дата загрузки нового файла заключения */
  dateChanged?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО врача */
  doctorFio?: InputMaybe<StringOperationFilterInput>;
  /** Должность врача */
  doctorPosition?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Новое имя файла заключения */
  newFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConclusionFileHistoryOutputFilterInput>>;
  /** Наименование организации */
  organizationName?: InputMaybe<StringOperationFilterInput>;
  /** Предыдущие имя файла заключения */
  prevFileName?: InputMaybe<StringOperationFilterInput>;
  /** Время загрузки нового файла заключения */
  timeChanged?: InputMaybe<ComparableTimeOnlyOperationFilterInput>;
  /** Момент времени загрузки нового файла заключения */
  trackedTime?: InputMaybe<StringOperationFilterInput>;
};

/** Выходная структура по истории загрузки файлов заключений */
export type ConclusionFileHistorySortInput = {
  /** Наименование услуги/обращения */
  appealType?: InputMaybe<SortEnumType>;
  /** ФИО клиента */
  clientFio?: InputMaybe<SortEnumType>;
  /** Дата выдачи заключения */
  conclusionDate?: InputMaybe<SortEnumType>;
  /** Наименование заключения */
  conclusionName?: InputMaybe<SortEnumType>;
  /** Номер заключения */
  conclusionNumber?: InputMaybe<SortEnumType>;
  /** Дата загрузки нового файла заключения */
  dateChanged?: InputMaybe<SortEnumType>;
  /** ФИО врача */
  doctorFio?: InputMaybe<SortEnumType>;
  /** Должность врача */
  doctorPosition?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Новое имя файла заключения */
  newFileName?: InputMaybe<SortEnumType>;
  /** Наименование организации */
  organizationName?: InputMaybe<SortEnumType>;
  /** Предыдущие имя файла заключения */
  prevFileName?: InputMaybe<SortEnumType>;
  /** Время загрузки нового файла заключения */
  timeChanged?: InputMaybe<SortEnumType>;
  /** Момент времени загрузки нового файла заключения */
  trackedTime?: InputMaybe<SortEnumType>;
};

/** Тип заключения врача */
export enum ConclusionType {
  /** Годен */
  GOODIN = "GOODIN",
  /** Не годен */
  UNFIT = "UNFIT",
}

/** Данные для создания заявки */
export type CreateAppealInput = {
  /** Тип услуги */
  appealTypeId: Scalars["Int"];
  /** Id сотрудника - тот кто создал обращения */
  employeeId: Scalars["UUID"];
  /** Дата обращение */
  requestDate: Scalars["Date"];
  /** Id пользователя */
  userId: Scalars["UUID"];
};

export type CreateAppealOpoInput = {
  /** Структурное подразделение */
  department: Scalars["String"];
  /** Должность сотрудника */
  employeePosition: Scalars["String"];
  /** Идентификатор ОКВЭД */
  okvedId: Scalars["Int"];
  /** Идентификатор вида деятельности из приложения к приказу 342н */
  opoWorkTypeId: Scalars["Int"];
  /** Идентификатор медицинской организации, проводящей ОПО */
  organizationInfoId: Scalars["Int"];
  /** Дата обращения */
  requestDate: Scalars["Date"];
  /** Id пользователя */
  userId: Scalars["UUID"];
};

export type CreateAppealOpoTemplateInput = {
  /** Массовая заявка ОПО */
  appealOpos: Array<CreateAppealOpoInput>;
  /** Название шаблона */
  name: Scalars["String"];
};

export type CreateAppealsInput = {
  /** Данные для создания заявок */
  input: Array<CreateAppealInput>;
};

/** Данные для создания заключения председателя ВК */
export type CreateCommissionHeadConclusionInput = {
  /** Идентификатор обращения */
  appealId: Scalars["Int"];
  /** Тип заключения */
  conclusionType: FinalAppealConclusionType;
  /** Id сотрудника */
  employeeId: Scalars["UUID"];
  /** Код медицинсокй организации */
  medicalOrganizationCode: Scalars["Int"];
  /** Наименование заключения */
  name: Scalars["String"];
  /** Идентификатор организации */
  organizationId?: InputMaybe<Scalars["Int"]>;
  /** Код региона */
  regionCode: Scalars["Int"];
  /** Дата окончания ограничения */
  restrictionDateEnd?: InputMaybe<Scalars["Date"]>;
  /** Временное ограничение по разрешению */
  restrictionType: RestrictionType;
};

export type CreateConclusionForOpoInput = {
  /** Идентификатор обращения */
  appealId: Scalars["Int"];
  /** Тип заключения */
  conclusionType: ConclusionType;
  /** Идентификатор диагноза из справочника МКБ-10 */
  diagnosisId?: InputMaybe<Scalars["Int"]>;
  /** Идентификатор сотрудника */
  employeeId: Scalars["UUID"];
  /** Код медицинсокй организации */
  medicalOrganizationCode: Scalars["Int"];
  /** Наименование заключения */
  name: Scalars["String"];
  /** Идентификатор организации */
  organizationId?: InputMaybe<Scalars["Int"]>;
  /** Код региона */
  regionCode: Scalars["Int"];
};

/** Данные для создания заключения врача */
export type CreateConclusionInput = {
  /** Идентификатор обращения */
  appealId: Scalars["Int"];
  /** Тип заключения */
  conclusionType: ConclusionType;
  /** Идентификатор диагноза из справочника МКБ-10 */
  diagnosisId?: InputMaybe<Scalars["Int"]>;
  /** Идентификатор сотрудника */
  employeeId: Scalars["UUID"];
  /** Код медицинсокй организации */
  medicalOrganizationCode: Scalars["Int"];
  /** Наименование заключения */
  name: Scalars["String"];
  /** Идентификатор организации */
  organizationId?: InputMaybe<Scalars["Int"]>;
  /** Код региона */
  regionCode: Scalars["Int"];
};

/** Данные для привязки ЭЦП к профилю */
export type CreateCspInput = {
  /** Подпись ЭЦП */
  cspSignature: Scalars["String"];
  /** Дата окончания срока годности ЭЦП */
  expires: Scalars["DateTime"];
};

/** Организация-работодатель */
export type CreateEmployingOrganizationInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkvedsIds: Array<Scalars["Int"]>;
  /** Адрес работодателя */
  address: Scalars["String"];
  /** Отчество  уполномоченного представителя работодателя */
  delagateFathersName: Scalars["String"];
  /** Имя уполномоченного представителя работодателя */
  delagateName: Scalars["String"];
  /** Фамилия уполномоченного представителя работодателя */
  delagateSurname: Scalars["String"];
  /** Адрес электронной почты */
  email: Scalars["String"];
  /** ИНН */
  inn: Scalars["String"];
  /** КПП */
  kpp: Scalars["String"];
  /** Основной ОКВЭД */
  mainOkvedId: Scalars["Int"];
  /** ОГРН */
  ogrn: Scalars["String"];
  /** Наименование работодателя */
  organizationName: Scalars["String"];
  /** Контактный номер телефона */
  phoneNumber: Scalars["String"];
};

/** Данные для создания массовой заявки ОПО из файла Excel */
export type CreateMultipleAppealOposFromFileInput = {
  /** Файл Excel с данными для заполнения массовой заявки ОПО */
  multipleAppealOpoFile: FileDtoInput;
  /** Идентификатор медицинской организации, проводящей ОПО */
  organizationInfoId: Scalars["Int"];
};

export type CreateMultipleAppealOposInput = {
  /** Данные для создания заявок */
  inputs: Array<CreateAppealOpoInput>;
};

/** Данные для создания организации */
export type CreateOrganizationInput = {
  /** Адрес */
  address: Scalars["String"];
  /** Email */
  email: Scalars["String"];
  /** Инн */
  inn: Scalars["String"];
  /** Кпп */
  kpp: Scalars["String"];
  /** Орган выдавший лицензию */
  licenseAuthority: Scalars["String"];
  /** Дата выдачи лиценции */
  licenseDate: Scalars["Date"];
  /** Файл лицензии */
  licenseFile: FileDtoInput;
  /** Номер лиценции */
  licenseNumber: Scalars["String"];
  /** Основной ОКВЭД */
  mainOkvedId: Scalars["Int"];
  /** Название */
  name: Scalars["String"];
  /** Огрн */
  ogrn: Scalars["String"];
  /** Код организации */
  organizationCode: Scalars["String"];
  /** Телефон */
  phone: Scalars["String"];
  /** Файл печати */
  printFile: FileDtoInput;
  /** Код республики */
  republicCode: Scalars["String"];
};

export type CreateTemplateForMultipleAppealOposInput = {
  /** Данные для создания шаблона */
  inputs: CreateAppealOpoTemplateInput;
};

/** Данные для создания заключения терапевта */
export type CreateTherapistConclusionInput = {
  /** Идентификатор обращения */
  appealId: Scalars["Int"];
  /** Тип заключения */
  conclusionType: FinalAppealConclusionType;
  /** Id сотрудника */
  employeeId: Scalars["UUID"];
  /** Код медицинсокй организации */
  medicalOrganizationCode: Scalars["Int"];
  /** Наименование заключения */
  name: Scalars["String"];
  /** Идентификатор организации */
  organizationId?: InputMaybe<Scalars["Int"]>;
  /** Код региона */
  regionCode: Scalars["Int"];
  /** Дата окончания ограничения */
  restrictionDateEnd?: InputMaybe<Scalars["Date"]>;
  /** Временное ограничение по разрешению */
  restrictionType: RestrictionType;
};

/** Данные для создания пользователя */
export type CreateUserInput = {
  /** Дата рождения */
  birthdate: Scalars["Date"];
  /** Email */
  email: Scalars["String"];
  /** Отчество */
  fathersName: Scalars["String"];
  /** Имя */
  name: Scalars["String"];
  /** Телефон */
  phoneNumber: Scalars["String"];
  /** Наименование роли */
  roleName: Scalars["String"];
  /** Пол пользователя */
  sex: Scalars["String"];
  /** СНИЛС */
  snils: Scalars["String"];
  /** Фамилия */
  surname: Scalars["String"];
};

/** Данные для удаления заявки */
export type DeleteAppealOpoInput = {
  /** Идентификатор заявки */
  id: Scalars["Int"];
};

export type DeleteAppealOpoTemplateInput = {
  /** Идентификатор файла шаблона */
  id: Scalars["Int"];
};

/** Данные для удаления заключения врача */
export type DeleteConclusionInput = {
  /** Идентификатор заключения врача */
  id: Scalars["Int"];
};

export type DeleteMultipleAppealOposTemplateInput = {
  /** Данные для удаления заключения */
  input: DeleteAppealOpoTemplateInput;
};

/** Данные для удаления заключения терапевта */
export type DeleteTherapistConclusionInput = {
  /** Идентификатор заключения терапевта */
  id: Scalars["Int"];
};

/** Данные для удаления пользователя */
export type DeleteUserInput = {
  /** Идентификатор пользователя */
  id: Scalars["UUID"];
};

/** Диагноз */
export type DiagnosisOutputFilterInput = {
  and?: InputMaybe<Array<DiagnosisOutputFilterInput>>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование диагноза */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DiagnosisOutputFilterInput>>;
};

/** Диагноз */
export type DiagnosisSortInput = {
  id?: InputMaybe<SortEnumType>;
  /** Наименование диагноза */
  name?: InputMaybe<SortEnumType>;
};

/** Организация-работодатель */
export type EmployingOrganizationOutputFilterInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkveds?: InputMaybe<ListFilterInputTypeOfOkvedOutputFilterInput>;
  /** Адрес */
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EmployingOrganizationOutputFilterInput>>;
  /** Отчество  уполномоченного представителя работодателя */
  delagateFathersName?: InputMaybe<StringOperationFilterInput>;
  /** Имя уполномоченного представителя работодателя */
  delagateName?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия уполномоченного представителя работодателя */
  delagateSurname?: InputMaybe<StringOperationFilterInput>;
  /** Адрес электронной почты */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Запрет входа по логину */
  hasLoginPassAccess?: InputMaybe<BooleanOperationFilterInput>;
  /** Идентификатор пользователя */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** ИНН */
  inn?: InputMaybe<StringOperationFilterInput>;
  /** Статус блокирования пользователя */
  isAccessBlocked?: InputMaybe<BooleanOperationFilterInput>;
  /** КПП */
  kpp?: InputMaybe<StringOperationFilterInput>;
  /** Логин */
  login?: InputMaybe<StringOperationFilterInput>;
  /** Основной ОКВЭД */
  mainOkved?: InputMaybe<OkvedOutputFilterInput>;
  /** ОГРН */
  ogrn?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployingOrganizationOutputFilterInput>>;
  /** Наименование работодателя */
  organizationName?: InputMaybe<StringOperationFilterInput>;
  /** Пароль */
  password?: InputMaybe<StringOperationFilterInput>;
  /** Контактный номер телефона */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Список Id профилей */
  profileIds?: InputMaybe<ListComparableInt32OperationFilterInput>;
  /** Список названий профилей */
  profileNames?: InputMaybe<StringOperationFilterInput>;
  /** Наименование роли */
  roleName?: InputMaybe<StringOperationFilterInput>;
};

/** Организация-работодатель */
export type EmployingOrganizationSortInput = {
  /** Адрес */
  address?: InputMaybe<SortEnumType>;
  /** Отчество  уполномоченного представителя работодателя */
  delagateFathersName?: InputMaybe<SortEnumType>;
  /** Имя уполномоченного представителя работодателя */
  delagateName?: InputMaybe<SortEnumType>;
  /** Фамилия уполномоченного представителя работодателя */
  delagateSurname?: InputMaybe<SortEnumType>;
  /** Адрес электронной почты */
  email?: InputMaybe<SortEnumType>;
  /** Запрет входа по логину */
  hasLoginPassAccess?: InputMaybe<SortEnumType>;
  /** Идентификатор пользователя */
  id?: InputMaybe<SortEnumType>;
  /** ИНН */
  inn?: InputMaybe<SortEnumType>;
  /** Статус блокирования пользователя */
  isAccessBlocked?: InputMaybe<SortEnumType>;
  /** КПП */
  kpp?: InputMaybe<SortEnumType>;
  /** Логин */
  login?: InputMaybe<SortEnumType>;
  /** Основной ОКВЭД */
  mainOkved?: InputMaybe<OkvedSortInput>;
  /** ОГРН */
  ogrn?: InputMaybe<SortEnumType>;
  /** Наименование работодателя */
  organizationName?: InputMaybe<SortEnumType>;
  /** Пароль */
  password?: InputMaybe<SortEnumType>;
  /** Контактный номер телефона */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Список названий профилей */
  profileNames?: InputMaybe<SortEnumType>;
  /** Наименование роли */
  roleName?: InputMaybe<SortEnumType>;
};

export type FileDtoFilterInput = {
  and?: InputMaybe<Array<FileDtoFilterInput>>;
  file?: InputMaybe<StringOperationFilterInput>;
  fileGuid?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileDtoFilterInput>>;
};

export type FileDtoInput = {
  file?: InputMaybe<Scalars["String"]>;
  fileGuid?: InputMaybe<Scalars["UUID"]>;
  fileName?: InputMaybe<Scalars["String"]>;
};

export type FileDtoSortInput = {
  file?: InputMaybe<SortEnumType>;
  fileGuid?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
};

/** Тип итогового заключения(разрешения) */
export enum FinalAppealConclusionType {
  /** Годен */
  GOODIN = "GOODIN",
  /** Не годен */
  UNFIT = "UNFIT",
}

/** История изменений */
export type HistoryOutputFilterInput = {
  and?: InputMaybe<Array<HistoryOutputFilterInput>>;
  /** Отчество пользователя */
  clientFathersName?: InputMaybe<StringOperationFilterInput>;
  /** ФИО пользователя */
  clientFio?: InputMaybe<StringOperationFilterInput>;
  /** Имя пользователя */
  clientName?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия пользователя */
  clientSurname?: InputMaybe<StringOperationFilterInput>;
  /** Новое значение */
  currentValue?: InputMaybe<StringOperationFilterInput>;
  /** Дата изменения данных */
  dateChanged?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО Администратора */
  employeeFio?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<HistoryOutputFilterInput>>;
  /** Имя организации */
  organizationName?: InputMaybe<StringOperationFilterInput>;
  /** Предыдущее значение */
  prevValue?: InputMaybe<StringOperationFilterInput>;
  /** Время изменения данных */
  timeChanged?: InputMaybe<ComparableTimeOnlyOperationFilterInput>;
  /** Момент времени изменения данных */
  trackedTime?: InputMaybe<StringOperationFilterInput>;
};

/** История изменений */
export type HistorySortInput = {
  /** Отчество пользователя */
  clientFathersName?: InputMaybe<SortEnumType>;
  /** ФИО пользователя */
  clientFio?: InputMaybe<SortEnumType>;
  /** Имя пользователя */
  clientName?: InputMaybe<SortEnumType>;
  /** Фамилия пользователя */
  clientSurname?: InputMaybe<SortEnumType>;
  /** Новое значение */
  currentValue?: InputMaybe<SortEnumType>;
  /** Дата изменения данных */
  dateChanged?: InputMaybe<SortEnumType>;
  /** ФИО Администратора */
  employeeFio?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Имя организации */
  organizationName?: InputMaybe<SortEnumType>;
  /** Предыдущее значение */
  prevValue?: InputMaybe<SortEnumType>;
  /** Время изменения данных */
  timeChanged?: InputMaybe<SortEnumType>;
  /** Момент времени изменения данных */
  trackedTime?: InputMaybe<SortEnumType>;
};

export type ListBooleanOperationFilterInput = {
  all?: InputMaybe<BooleanOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<BooleanOperationFilterInput>;
  some?: InputMaybe<BooleanOperationFilterInput>;
};

export type ListComparableDateOnlyOperationFilterInput = {
  all?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  some?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
};

export type ListComparableInt32OperationFilterInput = {
  all?: InputMaybe<ComparableInt32OperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ComparableInt32OperationFilterInput>;
  some?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ListFilterInputTypeOfAddressOutputFilterInput = {
  all?: InputMaybe<AddressOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AddressOutputFilterInput>;
  some?: InputMaybe<AddressOutputFilterInput>;
};

export type ListFilterInputTypeOfConclusionAppealOutputFilterInput = {
  all?: InputMaybe<ConclusionAppealOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ConclusionAppealOutputFilterInput>;
  some?: InputMaybe<ConclusionAppealOutputFilterInput>;
};

export type ListFilterInputTypeOfOkvedOutputFilterInput = {
  all?: InputMaybe<OkvedOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<OkvedOutputFilterInput>;
  some?: InputMaybe<OkvedOutputFilterInput>;
};

export type ListFilterInputTypeOfProfileOutputFilterInput = {
  all?: InputMaybe<ProfileOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ProfileOutputFilterInput>;
  some?: InputMaybe<ProfileOutputFilterInput>;
};

export type ListFilterInputTypeOfReportConclusionOutputFilterInput = {
  all?: InputMaybe<ReportConclusionOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ReportConclusionOutputFilterInput>;
  some?: InputMaybe<ReportConclusionOutputFilterInput>;
};

/** Данные для загрузки заключения врача */
export type LoadConclusionInput = {
  /** Файл заключения */
  file: FileDtoInput;
  id: Scalars["Int"];
};

/** Данные для загрузки заключения врача */
export type LoadTherapistConclusionInput = {
  /** Файл заключения */
  file: FileDtoInput;
  id: Scalars["Int"];
};

export type MakeClinicalPsychologistAppointmentInput = {
  /** Идентификатор заявки */
  id: Scalars["Int"];
};

export type MultipleAppealOpoOutputFilterInput = {
  and?: InputMaybe<Array<MultipleAppealOpoOutputFilterInput>>;
  /** Структурное подразделение */
  department?: InputMaybe<StringOperationFilterInput>;
  /** Должность сотрудника */
  employeePosition?: InputMaybe<StringOperationFilterInput>;
  /** фИО сотрудника */
  fio?: InputMaybe<StringOperationFilterInput>;
  /** ОКВЭД */
  okved?: InputMaybe<OkvedOutputFilterInput>;
  /** Вид деятельности из приложения к приказу 342н */
  opoWorkType?: InputMaybe<OpoWorkTypeOutputFilterInput>;
  or?: InputMaybe<Array<MultipleAppealOpoOutputFilterInput>>;
  /** Id пользователя */
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type MultipleAppealOpoSortInput = {
  /** Структурное подразделение */
  department?: InputMaybe<SortEnumType>;
  /** Должность сотрудника */
  employeePosition?: InputMaybe<SortEnumType>;
  /** фИО сотрудника */
  fio?: InputMaybe<SortEnumType>;
  /** ОКВЭД */
  okved?: InputMaybe<OkvedSortInput>;
  /** Вид деятельности из приложения к приказу 342н */
  opoWorkType?: InputMaybe<OpoWorkTypeSortInput>;
  /** Id пользователя */
  userId?: InputMaybe<SortEnumType>;
};

export type NullableOfRestrictionTypeOperationFilterInput = {
  eq?: InputMaybe<RestrictionType>;
  in?: InputMaybe<Array<InputMaybe<RestrictionType>>>;
  neq?: InputMaybe<RestrictionType>;
  nin?: InputMaybe<Array<InputMaybe<RestrictionType>>>;
};

/** ОКВЭД */
export type OkvedOutputFilterInput = {
  and?: InputMaybe<Array<OkvedOutputFilterInput>>;
  /** Код ОКВЭД */
  code?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование ОКВЭД */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OkvedOutputFilterInput>>;
};

/** ОКВЭД */
export type OkvedSortInput = {
  /** Код ОКВЭД */
  code?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Наименование ОКВЭД */
  name?: InputMaybe<SortEnumType>;
};

/** Вид деятельности из приложения к приказу 342н */
export type OpoWorkTypeOutputFilterInput = {
  and?: InputMaybe<Array<OpoWorkTypeOutputFilterInput>>;
  /** Код вида деятельности */
  code?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование вида деятельности */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OpoWorkTypeOutputFilterInput>>;
};

/** Вид деятельности из приложения к приказу 342н */
export type OpoWorkTypeSortInput = {
  /** Код вида деятельности */
  code?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Наименование вида деятельности */
  name?: InputMaybe<SortEnumType>;
};

/** Информация о медицинской организации */
export type OrganizationInfoOutputFilterInput = {
  and?: InputMaybe<Array<OrganizationInfoOutputFilterInput>>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<OrganizationInfoOutputFilterInput>>;
  /** Идентификатор медицинской организации */
  organizationId?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование медицинской организации */
  organizationName?: InputMaybe<StringOperationFilterInput>;
};

/** Информация о медицинской организации */
export type OrganizationInfoSortInput = {
  id?: InputMaybe<SortEnumType>;
  /** Идентификатор медицинской организации */
  organizationId?: InputMaybe<SortEnumType>;
  /** Наименование медицинской организации */
  organizationName?: InputMaybe<SortEnumType>;
};

/** Организация */
export type OrganizationOutputFilterInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkveds?: InputMaybe<ListFilterInputTypeOfOkvedOutputFilterInput>;
  /** Адрес */
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<OrganizationOutputFilterInput>>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор */
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Инн */
  inn?: InputMaybe<StringOperationFilterInput>;
  /** Кпп */
  kpp?: InputMaybe<StringOperationFilterInput>;
  /** Орган выдавший лицензию */
  licenseAuthority?: InputMaybe<StringOperationFilterInput>;
  /** Дата выдачи лиценции */
  licenseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Файл лицензии */
  licenseFile?: InputMaybe<FileDtoFilterInput>;
  /** Номер лиценции */
  licenseNumber?: InputMaybe<StringOperationFilterInput>;
  /** Основной ОКВЭД */
  mainOkved?: InputMaybe<OkvedOutputFilterInput>;
  /** Название */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Огрн */
  ogrn?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizationOutputFilterInput>>;
  /** Код организации */
  organizationCode?: InputMaybe<StringOperationFilterInput>;
  /** Телефон */
  phone?: InputMaybe<StringOperationFilterInput>;
  /** Файл печати */
  printFile?: InputMaybe<FileDtoFilterInput>;
  /** Код республики */
  republicCode?: InputMaybe<StringOperationFilterInput>;
};

/** Организация */
export type OrganizationSortInput = {
  /** Адрес */
  address?: InputMaybe<SortEnumType>;
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** Идентификатор */
  id?: InputMaybe<SortEnumType>;
  /** Инн */
  inn?: InputMaybe<SortEnumType>;
  /** Кпп */
  kpp?: InputMaybe<SortEnumType>;
  /** Орган выдавший лицензию */
  licenseAuthority?: InputMaybe<SortEnumType>;
  /** Дата выдачи лиценции */
  licenseDate?: InputMaybe<SortEnumType>;
  /** Файл лицензии */
  licenseFile?: InputMaybe<FileDtoSortInput>;
  /** Номер лиценции */
  licenseNumber?: InputMaybe<SortEnumType>;
  /** Основной ОКВЭД */
  mainOkved?: InputMaybe<OkvedSortInput>;
  /** Название */
  name?: InputMaybe<SortEnumType>;
  /** Огрн */
  ogrn?: InputMaybe<SortEnumType>;
  /** Код организации */
  organizationCode?: InputMaybe<SortEnumType>;
  /** Телефон */
  phone?: InputMaybe<SortEnumType>;
  /** Файл печати */
  printFile?: InputMaybe<FileDtoSortInput>;
  /** Код республики */
  republicCode?: InputMaybe<SortEnumType>;
};

/** Пациент */
export type PatientOutputFilterInput = {
  and?: InputMaybe<Array<PatientOutputFilterInput>>;
  /** Дата рождения */
  birthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО */
  fio?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор пользователя */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PatientOutputFilterInput>>;
  /** СНИЛС */
  snils?: InputMaybe<StringOperationFilterInput>;
};

/** Пациент */
export type PatientSortInput = {
  /** Дата рождения */
  birthdate?: InputMaybe<SortEnumType>;
  /** ФИО */
  fio?: InputMaybe<SortEnumType>;
  /** Идентификатор пользователя */
  id?: InputMaybe<SortEnumType>;
  /** СНИЛС */
  snils?: InputMaybe<SortEnumType>;
};

/** Профиль роли */
export type ProfileOutputFilterInput = {
  and?: InputMaybe<Array<ProfileOutputFilterInput>>;
  /** Id профиля */
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Наименование профиля */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProfileOutputFilterInput>>;
  /** Идентификатор роли */
  roleId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

/** Профиль роли */
export type ProfileSortInput = {
  /** Id профиля */
  id?: InputMaybe<SortEnumType>;
  /** Наименование профиля */
  name?: InputMaybe<SortEnumType>;
  /** Идентификатор роли */
  roleId?: InputMaybe<SortEnumType>;
};

/** Выходная сущность для формирования отчета */
export type ReportConclusionOutputFilterInput = {
  and?: InputMaybe<Array<ReportConclusionOutputFilterInput>>;
  /** Файл заключения */
  conclusionFile?: InputMaybe<FileDtoFilterInput>;
  /** Тип заключения (врача/терапевта) */
  conclusionType?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** ФИО врача */
  doctorFio?: InputMaybe<StringOperationFilterInput>;
  /** Id сотрудника врача */
  doctorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Должность врача */
  doctorPosition?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Номер заключения (врача/терапевта) */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportConclusionOutputFilterInput>>;
  /** Идентификатор родительского сущности Appeal */
  parentId?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Дата рождения пациента */
  patientBirthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО пациента */
  patientFio?: InputMaybe<StringOperationFilterInput>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Снилс пациента */
  patientSnils?: InputMaybe<StringOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Дата окончания ограничения (только для терапевта) */
  restrictionDateEnd?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Временное ограничение по разрешению (только для терапевта) */
  restrictionType?: InputMaybe<NullableOfRestrictionTypeOperationFilterInput>;
  /** Тип сущности */
  type?: InputMaybe<ConclusionAppealTypeOperationFilterInput>;
};

/** Выходная сущность для формирования отчета */
export type ReportOutputFilterInput = {
  and?: InputMaybe<Array<ReportOutputFilterInput>>;
  /** Получение ФИО сотрудника/сотрудников */
  doctorFio?: InputMaybe<StringOperationFilterInput>;
  /** Получение должности(должностей) сотрудника */
  doctorPosition?: InputMaybe<StringOperationFilterInput>;
  /** Список документов по услуги */
  documents?: InputMaybe<ListFilterInputTypeOfReportConclusionOutputFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Список статусов документов по услуге */
  isActual?: InputMaybe<ListBooleanOperationFilterInput>;
  /** Наименование услуги */
  name?: InputMaybe<StringOperationFilterInput>;
  /** Получение номера документа/докуметов по услуге */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReportOutputFilterInput>>;
  /** Дата рождения пациента */
  patientBirthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО пациента */
  patientFio?: InputMaybe<StringOperationFilterInput>;
  /** Id пациента */
  patientId?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Снилс пациента */
  patientSnils?: InputMaybe<StringOperationFilterInput>;
  /** Список дат получения документов по услуге */
  responseDate?: InputMaybe<ListComparableDateOnlyOperationFilterInput>;
  /** Текущий статус обращения */
  status?: InputMaybe<AppealStatusOperationFilterInput>;
};

/** Выходная сущность для формирования отчета */
export type ReportSortInput = {
  /** Получение ФИО сотрудника/сотрудников */
  doctorFio?: InputMaybe<SortEnumType>;
  /** Получение должности(должностей) сотрудника */
  doctorPosition?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения/результата услуги */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование услуги */
  name?: InputMaybe<SortEnumType>;
  /** Получение номера документа/докуметов по услуге */
  number?: InputMaybe<SortEnumType>;
  /** Дата рождения пациента */
  patientBirthdate?: InputMaybe<SortEnumType>;
  /** ФИО пациента */
  patientFio?: InputMaybe<SortEnumType>;
  /** Id пациента */
  patientId?: InputMaybe<SortEnumType>;
  /** Снилс пациента */
  patientSnils?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
  /** Текущий статус обращения */
  status?: InputMaybe<SortEnumType>;
};

/** Тип ограничения */
export enum RestrictionType {
  /** Ограничения отсутствует */
  NONE = "NONE",
  /** Временно НЕ допущен к труду */
  NOT_TEMPORARILY_WORK = "NOT_TEMPORARILY_WORK",
  /** Временно допущен к труду */
  TEMPORARILY_WORK = "TEMPORARILY_WORK",
}

/** Роль */
export type RoleOutputFilterInput = {
  and?: InputMaybe<Array<RoleOutputFilterInput>>;
  /** Идентификатор */
  id?: InputMaybe<StringOperationFilterInput>;
  /** Наименование */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoleOutputFilterInput>>;
  /** Список профилей роли */
  profiles?: InputMaybe<ListFilterInputTypeOfProfileOutputFilterInput>;
};

/** Роль */
export type RoleSortInput = {
  /** Идентификатор */
  id?: InputMaybe<SortEnumType>;
  /** Наименование */
  name?: InputMaybe<SortEnumType>;
};

export type SetCommissionSizeInput = {
  /** Идентификатор заявки */
  id: Scalars["Int"];
  /** Размер врачебной комиссии */
  size: Scalars["Int"];
};

/** Пол */
export enum Sex {
  /** Женский */
  FEMALE = "FEMALE",
  /** Мужской */
  MALE = "MALE",
}

export type SexOperationFilterInput = {
  eq?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  neq?: InputMaybe<Sex>;
  nin?: InputMaybe<Array<Sex>>;
};

export type SignAppealOpoAgreementInput = {
  /** Идентификатор заявки ОПО */
  appealOpoId: Scalars["Int"];
};

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ncontains?: InputMaybe<Scalars["String"]>;
  nendsWith?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nstartsWith?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type UpdateAppealOpoTemplateInput = {
  /** Идентификатор файла шаблона */
  id: Scalars["Int"];
  /** Новое наименование файла */
  newName: Scalars["String"];
};

export type UpdateEmployingOrganizationInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkvedsIds: Array<Scalars["Int"]>;
  /** Адрес организации */
  address: Scalars["String"];
  /** Отчество  уполномоченного представителя работодателя */
  delagateFathersName: Scalars["String"];
  /** Имя уполномоченного представителя работодателя */
  delagateName: Scalars["String"];
  /** Фамилия уполномоченного представителя работодателя */
  delagateSurname: Scalars["String"];
  /** Адрес электронной почты */
  email: Scalars["String"];
  /** Запрет входа по логину */
  hasLoginPassAccess: Scalars["Boolean"];
  /** Идентификатор пользователя */
  id: Scalars["UUID"];
  /** ИНН */
  inn: Scalars["String"];
  /** Статус блокирования пользователя */
  isAccessBlocked: Scalars["Boolean"];
  /** КПП */
  kpp: Scalars["String"];
  /** Основной ОКВЭД */
  mainOkvedId: Scalars["Int"];
  /** ОГРН */
  ogrn: Scalars["String"];
  /** Наименование работодателя */
  organizationName: Scalars["String"];
  /** Пароль */
  password: Scalars["String"];
  /** Контактный номер телефона */
  phoneNumber: Scalars["String"];
};

export type UpdateEmployingOrganizationProfileInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkvedsIds: Array<Scalars["Int"]>;
  /** Адрес организации */
  address: Scalars["String"];
  /** Отчество  уполномоченного представителя работодателя */
  delagateFathersName: Scalars["String"];
  /** Имя уполномоченного представителя работодателя */
  delagateName: Scalars["String"];
  /** Фамилия уполномоченного представителя работодателя */
  delagateSurname: Scalars["String"];
  /** Адрес электронной почты */
  email: Scalars["String"];
  id: Scalars["UUID"];
  /** Основной ОКВЭД */
  mainOkvedId: Scalars["Int"];
  /** Наименование работодателя */
  organizationName: Scalars["String"];
  /** Контактный номер телефона */
  phoneNumber: Scalars["String"];
};

export type UpdateMultipleAppealOposTemplateInput = {
  /** Данные для переименования шаблона */
  input: UpdateAppealOpoTemplateInput;
};

/** Данные для обновления организации */
export type UpdateOrganizationInput = {
  /** Дополнительные ОКВЭД'ы */
  additionalOkvedIds: Array<Scalars["Int"]>;
  /** Адрес */
  address: Scalars["String"];
  /** Email */
  email: Scalars["String"];
  id: Scalars["Int"];
  /** Инн */
  inn: Scalars["String"];
  /** Кпп */
  kpp: Scalars["String"];
  /** Орган выдавший лицензию */
  licenseAuthority: Scalars["String"];
  /** Дата выдачи лиценции */
  licenseDate: Scalars["Date"];
  /** Файл лицензии */
  licenseFile: FileDtoInput;
  /** Номер лиценции */
  licenseNumber: Scalars["String"];
  /** Основной ОКВЭД */
  mainOkvedId: Scalars["Int"];
  /** Название */
  name: Scalars["String"];
  /** Огрн */
  ogrn: Scalars["String"];
  /** Код организации */
  organizationCode: Scalars["String"];
  /** Телефон */
  phone: Scalars["String"];
  /** Файл печати */
  printFile: FileDtoInput;
  /** Код республики */
  republicCode: Scalars["String"];
};

/** Данные для обновления пользователя */
export type UpdateUserInput = {
  /** Дата рождения */
  birthdate: Scalars["Date"];
  /** Email */
  email: Scalars["String"];
  /** Отчество */
  fathersName: Scalars["String"];
  /** Доступ по логину/паролю */
  hasLoginPassAccess: Scalars["Boolean"];
  /** Идентификатор */
  id: Scalars["UUID"];
  /** Статус блокирования пользователя */
  isAccessBlocked: Scalars["Boolean"];
  /** Является председателем ВК */
  isCommissionHead?: InputMaybe<Scalars["Boolean"]>;
  /** Логин */
  login: Scalars["String"];
  /** Имя */
  name: Scalars["String"];
  /** Id организации */
  organizationId: Scalars["Int"];
  /** Пароль */
  password: Scalars["String"];
  /** Номер телефона */
  phoneNumber: Scalars["String"];
  /** Должность */
  position: Scalars["String"];
  /** Список Id профилей */
  profileIds: Array<Scalars["Int"]>;
  /** Наименование роли */
  roleName: Scalars["String"];
  /** Пол пользователя */
  sex: Sex;
  /** СНИЛС */
  snils: Scalars["String"];
  /** Фамилия */
  surname: Scalars["String"];
};

/** Пользователь */
export type UserOutputFilterInput = {
  and?: InputMaybe<Array<UserOutputFilterInput>>;
  /** Дата рождения */
  birthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** Идентификатор врача являющегося председателем ВК */
  commissionHeadUserId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Отчество */
  fathersName?: InputMaybe<StringOperationFilterInput>;
  /** ФИО */
  fio?: InputMaybe<StringOperationFilterInput>;
  /** Запрет входа по логину */
  hasLoginPassAccess?: InputMaybe<BooleanOperationFilterInput>;
  /** Идентификатор пользователя */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Статус блокирования пользователя */
  isAccessBlocked?: InputMaybe<BooleanOperationFilterInput>;
  /** Логин */
  login?: InputMaybe<StringOperationFilterInput>;
  /** Имя */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserOutputFilterInput>>;
  /** Id организации */
  organizationId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  /** Название организации */
  organizationName?: InputMaybe<StringOperationFilterInput>;
  /** Пароль */
  password?: InputMaybe<StringOperationFilterInput>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Должность */
  position?: InputMaybe<StringOperationFilterInput>;
  /** Список Id профилей */
  profileIds?: InputMaybe<ListComparableInt32OperationFilterInput>;
  /** Список названий профилей */
  profileNames?: InputMaybe<StringOperationFilterInput>;
  /** Список профилей (только для выбора в компоненте) */
  profiles?: InputMaybe<ListFilterInputTypeOfProfileOutputFilterInput>;
  /** Наименование роли */
  roleName?: InputMaybe<StringOperationFilterInput>;
  /** Пол пользователя */
  sex?: InputMaybe<SexOperationFilterInput>;
  /** СНИЛС */
  snils?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия */
  surname?: InputMaybe<StringOperationFilterInput>;
};

/** Пользователь */
export type UserSortInput = {
  /** Дата рождения */
  birthdate?: InputMaybe<SortEnumType>;
  /** Идентификатор врача являющегося председателем ВК */
  commissionHeadUserId?: InputMaybe<SortEnumType>;
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** Отчество */
  fathersName?: InputMaybe<SortEnumType>;
  /** ФИО */
  fio?: InputMaybe<SortEnumType>;
  /** Запрет входа по логину */
  hasLoginPassAccess?: InputMaybe<SortEnumType>;
  /** Идентификатор пользователя */
  id?: InputMaybe<SortEnumType>;
  /** Статус блокирования пользователя */
  isAccessBlocked?: InputMaybe<SortEnumType>;
  /** Логин */
  login?: InputMaybe<SortEnumType>;
  /** Имя */
  name?: InputMaybe<SortEnumType>;
  /** Id организации */
  organizationId?: InputMaybe<SortEnumType>;
  /** Название организации */
  organizationName?: InputMaybe<SortEnumType>;
  /** Пароль */
  password?: InputMaybe<SortEnumType>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Должность */
  position?: InputMaybe<SortEnumType>;
  /** Список названий профилей */
  profileNames?: InputMaybe<SortEnumType>;
  /** Наименование роли */
  roleName?: InputMaybe<SortEnumType>;
  /** Пол пользователя */
  sex?: InputMaybe<SortEnumType>;
  /** СНИЛС */
  snils?: InputMaybe<SortEnumType>;
  /** Фамилия */
  surname?: InputMaybe<SortEnumType>;
};

export type OkvedViewFragment = { id: number; code: string; name: string };

export type OkvedsQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<OkvedSortInput> | OkvedSortInput>;
  where: InputMaybe<OkvedOutputFilterInput>;
}>;

export type OkvedsQuery = {
  okveds: {
    totalCount: number;
    items: Array<{ id: number; code: string; name: string }> | null;
  } | null;
};

export type OpoWorkTypeViewFragment = {
  id: number;
  code: string;
  name: string;
};

export type OpoWorkTypesQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<OpoWorkTypeSortInput> | OpoWorkTypeSortInput>;
  where: InputMaybe<OpoWorkTypeOutputFilterInput>;
}>;

export type OpoWorkTypesQuery = {
  opoWorkTypes: {
    totalCount: number;
    items: Array<{ id: number; code: string; name: string }> | null;
  } | null;
};

export type OrganizationInfoViewFragment = {
  id: number;
  organizationId: number;
  organizationName: string;
};

export type OrganizationInfosQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<OrganizationInfoSortInput> | OrganizationInfoSortInput
  >;
  where: InputMaybe<OrganizationInfoOutputFilterInput>;
}>;

export type OrganizationInfosQuery = {
  organizationInfos: {
    totalCount: number;
    items: Array<{
      id: number;
      organizationId: number;
      organizationName: string;
    }> | null;
  } | null;
};

export type AppealTypeItemFragment = { id: number; name: string };

export type ActiveAppealTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveAppealTypesQuery = {
  activeAppealTypes: {
    totalCount: number;
    items: Array<{ id: number; name: string }> | null;
  } | null;
};

export type AppealViewFragment = {
  id: number;
  name: string;
  status: AppealStatus;
  isActual: boolean;
  responseDate: any | null;
  patientId: any;
};

export type AppealQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealQuery = {
  appeal: {
    id: number;
    name: string;
    status: AppealStatus;
    isActual: boolean;
    responseDate: any | null;
    patientId: any;
  };
};

export type AppealQueryFragmentFragment = {
  id: number;
  employeePosition: string;
};

export type AppealOpoQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealOpoQuery = {
  appealOpoById: { id: number; employeePosition: string };
};

export type AppealOpoAgreementViewFragment = { agreement: boolean };

export type AppealOpoAgreementQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealOpoAgreementQuery = { appealOpoById: { agreement: boolean } };

export type AppealByEmployingOrganizationViewFragment = {
  id: number;
  name: string;
  isActual: boolean;
  documents: Array<{
    id: number;
    name: string;
    number: string | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  }>;
};

export type AppealOpoByEmployingOrganizationQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealOpoByEmployingOrganizationQuery = {
  appealOpoById: {
    id: number;
    name: string;
    isActual: boolean;
    documents: Array<{
      id: number;
      name: string;
      number: string | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    }>;
  };
};

export type AppealOpoClinicalPsychologistAppointmentFragment = {
  clinicalPsychologistAppointment: boolean;
};

export type AppealOpoClinicalPsychologistAppointmentQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealOpoClinicalPsychologistAppointmentQuery = {
  appealOpoById: { clinicalPsychologistAppointment: boolean };
};

export type AppealOpoCommissionSizeFragment = { commissionSize: number | null };

export type AppealOpoCommissionSizeQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AppealOpoCommissionSizeQuery = {
  appealOpoById: { commissionSize: number | null };
};

export type AppealOpoViewFragment = {
  id: number;
  name: string;
  status: AppealStatus;
  isActual: boolean;
  responseDate: any | null;
  patientId: any;
  isAbleToCancel: boolean;
};

export type AppealOposQueryVariables = Exact<{
  userId: Scalars["UUID"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<AppealOpoEntitySortInput> | AppealOpoEntitySortInput>;
  where: InputMaybe<AppealOpoEntityOutputFilterInput>;
}>;

export type AppealOposQuery = {
  appealOpos: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      status: AppealStatus;
      isActual: boolean;
      responseDate: any | null;
      patientId: any;
      isAbleToCancel: boolean;
    }> | null;
  } | null;
};

export type AppealTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AppealTypesQuery = {
  appealTypes: {
    totalCount: number;
    items: Array<{ id: number; name: string }> | null;
  } | null;
};

export type AppealsQueryVariables = Exact<{
  id: Scalars["UUID"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<AppealSortInput> | AppealSortInput>;
  where: InputMaybe<AppealOutputFilterInput>;
}>;

export type AppealsQuery = {
  appeals: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      status: AppealStatus;
      isActual: boolean;
      responseDate: any | null;
      patientId: any;
    }> | null;
  } | null;
};

export type AppealByAdminViewFragment = {
  id: number;
  name: string;
  isActual: boolean;
  documents: Array<{
    id: number;
    name: string;
    number: string | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  }>;
};

export type AppealsByAdminQueryVariables = Exact<{
  id: Scalars["UUID"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<AppealEntitySortInput> | AppealEntitySortInput>;
  where: InputMaybe<AppealEntityOutputFilterInput>;
}>;

export type AppealsByAdminQuery = {
  appealsByAdmin: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      isActual: boolean;
      documents: Array<{
        id: number;
        name: string;
        number: string | null;
        responseDate: any | null;
        isActual: boolean;
        conclusionFile: { fileName: string | null; file: string | null } | null;
      }>;
    }> | null;
  } | null;
};

export type ConclusionAppealViewFragment = {
  id: number;
  parentId: number;
  patientId: any;
  name: string;
  number: string | null;
  type: ConclusionAppealType;
  conclusionType: number;
  restrictionType: RestrictionType | null;
  restrictionDateEnd: any | null;
  responseDate: any | null;
  doctorId: any;
  isActual: boolean;
  conclusionFile: { fileName: string | null; file: string | null } | null;
};

export type ConclusionAppealQueryVariables = Exact<{
  id: Scalars["Int"];
  roleName: Scalars["String"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<ConclusionAppealSortInput> | ConclusionAppealSortInput
  >;
  where: InputMaybe<ConclusionAppealOutputFilterInput>;
}>;

export type ConclusionAppealQuery = {
  conclusionAppeals: {
    totalCount: number;
    items: Array<{
      id: number;
      parentId: number;
      patientId: any;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      doctorId: any;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    }> | null;
  } | null;
};

export type CreateAppealsPayloadFragment = {
  appealEntity: Array<{
    id: number;
    name: string;
    status: AppealStatus;
    isActual: boolean;
    responseDate: any | null;
  }> | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateAppealMutationVariables = Exact<{
  input: CreateAppealsInput;
}>;

export type CreateAppealMutation = {
  createAppeals: {
    appealEntity: Array<{
      id: number;
      name: string;
      status: AppealStatus;
      isActual: boolean;
      responseDate: any | null;
    }> | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type CreateConclusionPayloadFragment = {
  conclusionAppeal: {
    id: number;
    parentId: number;
    name: string;
    number: string | null;
    type: ConclusionAppealType;
    conclusionType: number;
    restrictionType: RestrictionType | null;
    restrictionDateEnd: any | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateConclusionMutationVariables = Exact<{
  input: CreateConclusionInput;
}>;

export type CreateConclusionMutation = {
  createConclusion: {
    conclusionAppeal: {
      id: number;
      parentId: number;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type CreateConclusionForOpoPayloadFragment = {
  conclusionAppeal: {
    id: number;
    parentId: number;
    name: string;
    number: string | null;
    type: ConclusionAppealType;
    conclusionType: number;
    restrictionType: RestrictionType | null;
    restrictionDateEnd: any | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateConclusionForOpoMutationVariables = Exact<{
  input: CreateConclusionForOpoInput;
}>;

export type CreateConclusionForOpoMutation = {
  createConclusionForOpo: {
    conclusionAppeal: {
      id: number;
      parentId: number;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type CreateTherapistConclusionPayloadFragment = {
  conclusionAppeal: {
    id: number;
    parentId: number;
    name: string;
    number: string | null;
    type: ConclusionAppealType;
    conclusionType: number;
    restrictionType: RestrictionType | null;
    restrictionDateEnd: any | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateTherapistConclusionMutationVariables = Exact<{
  input: CreateTherapistConclusionInput;
}>;

export type CreateTherapistConclusionMutation = {
  createTherapistConclusion: {
    conclusionAppeal: {
      id: number;
      parentId: number;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type DeleteAppealOpoPayloadFragment = {
  int: number | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type DeleteAppealOpoMutationVariables = Exact<{
  input: DeleteAppealOpoInput;
}>;

export type DeleteAppealOpoMutation = {
  deleteAppealOpo: {
    int: number | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type DeleteConclusionPayloadFragment = {
  errors: Array<{ message: string } | { message: string }> | null;
};

export type DeleteConclusionMutationVariables = Exact<{
  id: DeleteConclusionInput;
}>;

export type DeleteConclusionMutation = {
  deleteConclusion: {
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type DeleteTherapistConclusionPayloadFragment = {
  errors: Array<{ message: string } | { message: string }> | null;
};

export type DeleteTherapistConclusionMutationVariables = Exact<{
  id: DeleteTherapistConclusionInput;
}>;

export type DeleteTherapistConclusionMutation = {
  deleteTherapistConclusion: {
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type DiagnosisViewFragment = { id: number; name: string };

export type DiagnosesQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<DiagnosisSortInput> | DiagnosisSortInput>;
  where: InputMaybe<DiagnosisOutputFilterInput>;
}>;

export type DiagnosesQuery = {
  diagnoses: {
    totalCount: number;
    items: Array<{ id: number; name: string }> | null;
  } | null;
};

export type LoadConclusionPayloadFragment = {
  conclusionAppeal: {
    id: number;
    parentId: number;
    name: string;
    number: string | null;
    type: ConclusionAppealType;
    conclusionType: number;
    restrictionType: RestrictionType | null;
    restrictionDateEnd: any | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type LoadConclusionMutationVariables = Exact<{
  input: LoadConclusionInput;
}>;

export type LoadConclusionMutation = {
  loadConclusion: {
    conclusionAppeal: {
      id: number;
      parentId: number;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type LoadTherapistConclusionPayloadFragment = {
  conclusionAppeal: {
    id: number;
    parentId: number;
    name: string;
    number: string | null;
    type: ConclusionAppealType;
    conclusionType: number;
    restrictionType: RestrictionType | null;
    restrictionDateEnd: any | null;
    responseDate: any | null;
    isActual: boolean;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type LoadTherapistConclusionMutationVariables = Exact<{
  input: LoadTherapistConclusionInput;
}>;

export type LoadTherapistConclusionMutation = {
  loadTherapistConclusion: {
    conclusionAppeal: {
      id: number;
      parentId: number;
      name: string;
      number: string | null;
      type: ConclusionAppealType;
      conclusionType: number;
      restrictionType: RestrictionType | null;
      restrictionDateEnd: any | null;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type MakeClinicalPsychologistAppointmentPayloadFragment = {
  int: number | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type MakeClinicalPsychologistAppointmentMutationVariables = Exact<{
  input: MakeClinicalPsychologistAppointmentInput;
}>;

export type MakeClinicalPsychologistAppointmentMutation = {
  makeClinicalPsychologistAppointment: {
    int: number | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type SetCommissionSizePayloadFragment = {
  int: number | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type SetCommissionSizeMutationVariables = Exact<{
  input: SetCommissionSizeInput;
}>;

export type SetCommissionSizeMutation = {
  setCommissionSize: {
    int: number | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type SignAppealOpoAgreementPayloadFragment = {
  int: number | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type SignAppealOpoAgreementMutationVariables = Exact<{
  input: SignAppealOpoAgreementInput;
}>;

export type SignAppealOpoAgreementMutation = {
  signAppealOpoAgreement: {
    int: number | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type ConclusionFileHistoryItemFragment = {
  id: number;
  organizationName: string | null;
  appealType: string;
  clientFio: string;
  doctorFio: string;
  doctorPosition: string;
  conclusionName: string;
  conclusionNumber: string;
  conclusionDate: any;
  prevFileName: string;
  newFileName: string;
  dateChanged: any;
  trackedTime: string;
};

export type GetConclusionFileHistoriesQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<ConclusionFileHistorySortInput> | ConclusionFileHistorySortInput
  >;
  where: InputMaybe<ConclusionFileHistoryOutputFilterInput>;
}>;

export type GetConclusionFileHistoriesQuery = {
  conclusionFileHistories: {
    totalCount: number;
    items: Array<{
      id: number;
      organizationName: string | null;
      appealType: string;
      clientFio: string;
      doctorFio: string;
      doctorPosition: string;
      conclusionName: string;
      conclusionNumber: string;
      conclusionDate: any;
      prevFileName: string;
      newFileName: string;
      dateChanged: any;
      trackedTime: string;
    }> | null;
  } | null;
};

export type HistoryItemFragment = {
  id: number;
  currentValue: string | null;
  dateChanged: any;
  trackedTime: string;
  prevValue: string | null;
  clientFio: string;
  employeeFio: string;
  organizationName: string;
};

export type GetClientsHistoryAsyncQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<HistorySortInput> | HistorySortInput>;
  where: InputMaybe<HistoryOutputFilterInput>;
}>;

export type GetClientsHistoryAsyncQuery = {
  clientsHistory: {
    totalCount: number;
    items: Array<{
      id: number;
      currentValue: string | null;
      dateChanged: any;
      trackedTime: string;
      prevValue: string | null;
      clientFio: string;
      employeeFio: string;
      organizationName: string;
    }> | null;
  } | null;
};

export type CreateAppealOpoPayloadFragment = {
  appealOpo: {
    id: number;
    employeePosition: string;
    department: string | null;
    okvedId: number;
    opoWorkTypeId: number;
    organizationInfoId: number;
    commissionSize: number | null;
    clinicalPsychologistAppointment: boolean;
    agreement: boolean;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateAppealOpoMutationVariables = Exact<{
  input: CreateAppealOpoInput;
}>;

export type CreateAppealOpoMutation = {
  createAppealOpo: {
    appealOpo: {
      id: number;
      employeePosition: string;
      department: string | null;
      okvedId: number;
      opoWorkTypeId: number;
      organizationInfoId: number;
      commissionSize: number | null;
      clinicalPsychologistAppointment: boolean;
      agreement: boolean;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type ClientViewFragment = {
  id: any;
  name: string;
  surname: string;
  fathersName: string | null;
  sex: Sex;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  addresses: Array<{
    fullAddress: string;
    startDate: any;
    endDate: any | null;
  }>;
};

export type ClientQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ClientQuery = {
  client: {
    id: any;
    name: string;
    surname: string;
    fathersName: string | null;
    sex: Sex;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    addresses: Array<{
      fullAddress: string;
      startDate: any;
      endDate: any | null;
    }>;
  };
};

export type ClientItemFragment = {
  id: any;
  name: string;
  surname: string;
  fathersName: string | null;
  fio: string;
  sex: Sex;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  addresses: Array<{
    fullAddress: string;
    startDate: any;
    endDate: any | null;
  }>;
};

export type ClientsQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<ClientSortInput> | ClientSortInput>;
  where: InputMaybe<ClientOutputFilterInput>;
}>;

export type ClientsQuery = {
  clients: {
    totalCount: number;
    items: Array<{
      id: any;
      name: string;
      surname: string;
      fathersName: string | null;
      fio: string;
      sex: Sex;
      birthdate: any;
      snils: string;
      phoneNumber: string | null;
      email: string;
      addresses: Array<{
        fullAddress: string;
        startDate: any;
        endDate: any | null;
      }>;
    }> | null;
  } | null;
};

export type PatientItemFragment = {
  id: any;
  fio: string;
  birthdate: any;
  snils: string;
};

export type PatientsQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<PatientSortInput> | PatientSortInput>;
  where: InputMaybe<PatientOutputFilterInput>;
}>;

export type PatientsQuery = {
  employingOrganizationPatients: {
    totalCount: number;
    items: Array<{
      id: any;
      fio: string;
      birthdate: any;
      snils: string;
    }> | null;
  } | null;
};

export type CreateMultipleAppealOpoPayloadFragment = {
  appealOpo: Array<{
    id: number;
    employeePosition: string;
    department: string | null;
    okvedId: number;
    opoWorkTypeId: number;
    organizationInfoId: number;
    commissionSize: number | null;
    clinicalPsychologistAppointment: boolean;
    agreement: boolean;
  }> | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateMultipleAppealOposMutationVariables = Exact<{
  input: CreateMultipleAppealOposInput;
}>;

export type CreateMultipleAppealOposMutation = {
  createMultipleAppealOpos: {
    appealOpo: Array<{
      id: number;
      employeePosition: string;
      department: string | null;
      okvedId: number;
      opoWorkTypeId: number;
      organizationInfoId: number;
      commissionSize: number | null;
      clinicalPsychologistAppointment: boolean;
      agreement: boolean;
    }> | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type CreateMultipleAppealOposFromFilePayloadFragment = {
  appealOpo: Array<{
    id: number;
    employeePosition: string;
    department: string | null;
    okvedId: number;
    opoWorkTypeId: number;
    organizationInfoId: number;
    commissionSize: number | null;
    clinicalPsychologistAppointment: boolean;
    agreement: boolean;
  }> | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateMultipleAppealOposFromFileMutationVariables = Exact<{
  input: CreateMultipleAppealOposFromFileInput;
}>;

export type CreateMultipleAppealOposFromFileMutation = {
  createMultipleAppealOposFromFile: {
    appealOpo: Array<{
      id: number;
      employeePosition: string;
      department: string | null;
      okvedId: number;
      opoWorkTypeId: number;
      organizationInfoId: number;
      commissionSize: number | null;
      clinicalPsychologistAppointment: boolean;
      agreement: boolean;
    }> | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type MultipleAppealOpoFromTemplateItemFragment = {
  fio: string;
  department: string;
  employeePosition: string;
  userId: any;
  okved: { id: number; code: string; name: string };
  opoWorkType: { id: number; code: string; name: string };
};

export type MultipleAppealOpoFromTemplateQueryVariables = Exact<{
  templateFileId: Scalars["UUID"];
}>;

export type MultipleAppealOpoFromTemplateQuery = {
  multipleAppealOposFromTemplate: {
    totalCount: number;
    items: Array<{
      fio: string;
      department: string;
      employeePosition: string;
      userId: any;
      okved: { id: number; code: string; name: string };
      opoWorkType: { id: number; code: string; name: string };
    }> | null;
  } | null;
};

export type CreateTemplateForMultipleAppealOposPayloadFragment = {
  uUID: any | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type CreateTemplateForMultipleAppealOposMutationVariables = Exact<{
  input: CreateTemplateForMultipleAppealOposInput;
}>;

export type CreateTemplateForMultipleAppealOposMutation = {
  createTemplateForMultipleAppealOpos: {
    uUID: any | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type DeleteTemplateForMultipleAppealOposPayloadFragment = {
  appealOpoTemplate: {
    id: number;
    name: string;
    templateFileId: any | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type DeleteTemplateForMultipleAppealOposMutationVariables = Exact<{
  input: DeleteMultipleAppealOposTemplateInput;
}>;

export type DeleteTemplateForMultipleAppealOposMutation = {
  deleteMultipleAppealOposTemplate: {
    appealOpoTemplate: {
      id: number;
      name: string;
      templateFileId: any | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type UpdateTemplateForMultipleAppealOposPayloadFragment = {
  appealOpoTemplate: {
    id: number;
    name: string;
    templateFileId: any | null;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type UpdateTemplateForMultipleAppealOposMutationVariables = Exact<{
  input: UpdateMultipleAppealOposTemplateInput;
}>;

export type UpdateTemplateForMultipleAppealOposMutation = {
  updateMultipleAppealOposTemplate: {
    appealOpoTemplate: {
      id: number;
      name: string;
      templateFileId: any | null;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type MultipleAppealOpoTemplateFragment = {
  id: number;
  name: string;
  templateFileId: any | null;
};

export type MultipleAppealOpoTemplatesQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<AppealOpoTemplateSortInput> | AppealOpoTemplateSortInput
  >;
  where: InputMaybe<AppealOpoTemplateOutputFilterInput>;
}>;

export type MultipleAppealOpoTemplatesQuery = {
  multipleAppealOpoTemplates: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      templateFileId: any | null;
    }> | null;
  } | null;
};

export type CreateEmployingOrganizationPayloadFragment = {
  employingOrganization: {
    id: any;
    organizationName: string;
    address: string;
    email: string;
    phoneNumber: string;
    delagateName: string;
    delagateSurname: string;
    delagateFathersName: string;
    ogrn: string;
    inn: string;
    kpp: string;
    mainOkved: { id: number; code: string; name: string } | null;
    additionalOkveds: Array<{ id: number; code: string; name: string }>;
  } | null;
  errors: Array<{ message: string }> | null;
};

export type CreateEmployingOrganizationMutationVariables = Exact<{
  input: CreateEmployingOrganizationInput;
}>;

export type CreateEmployingOrganizationMutation = {
  createEmployingOrganization: {
    employingOrganization: {
      id: any;
      organizationName: string;
      address: string;
      email: string;
      phoneNumber: string;
      delagateName: string;
      delagateSurname: string;
      delagateFathersName: string;
      ogrn: string;
      inn: string;
      kpp: string;
      mainOkved: { id: number; code: string; name: string } | null;
      additionalOkveds: Array<{ id: number; code: string; name: string }>;
    } | null;
    errors: Array<{ message: string }> | null;
  };
};

export type CreateOrganizationSuccessFragment = {
  data: number;
  message: string | null;
};

export type CreateOrganizationFailureFragment = {
  errors: Array<{ code: string; message: string }> | null;
};

export type CreateOrganizationPayloadFragment = {
  iCreateOrganizationResult:
    | { errors: Array<{ code: string; message: string }> | null }
    | { data: number; message: string | null }
    | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  createOrganization: {
    iCreateOrganizationResult:
      | { errors: Array<{ code: string; message: string }> | null }
      | { data: number; message: string | null }
      | null;
  };
};

export type EmployingOrganizationViewFragment = {
  id: any;
  organizationName: string;
  address: string;
  email: string;
  phoneNumber: string;
  delagateName: string;
  delagateSurname: string;
  delagateFathersName: string;
  ogrn: string;
  inn: string;
  kpp: string;
  hasLoginPassAccess: boolean;
  isAccessBlocked: boolean;
  login: string;
  password: string;
  mainOkved: { id: number; code: string; name: string } | null;
  additionalOkveds: Array<{ id: number; code: string; name: string }>;
};

export type EmployingOrganizationQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type EmployingOrganizationQuery = {
  employingOrganizationById: {
    id: any;
    organizationName: string;
    address: string;
    email: string;
    phoneNumber: string;
    delagateName: string;
    delagateSurname: string;
    delagateFathersName: string;
    ogrn: string;
    inn: string;
    kpp: string;
    hasLoginPassAccess: boolean;
    isAccessBlocked: boolean;
    login: string;
    password: string;
    mainOkved: { id: number; code: string; name: string } | null;
    additionalOkveds: Array<{ id: number; code: string; name: string }>;
  };
};

export type EmployingOrganizationFragment = {
  id: any;
  organizationName: string;
  address: string;
};

export type EmployingOrganizationsQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<EmployingOrganizationSortInput> | EmployingOrganizationSortInput
  >;
  where: InputMaybe<EmployingOrganizationOutputFilterInput>;
}>;

export type EmployingOrganizationsQuery = {
  employingOrganizationsList: {
    totalCount: number;
    items: Array<{ id: any; organizationName: string; address: string }> | null;
  } | null;
};

export type OrganizationViewFragment = {
  id: number;
  name: string;
  address: string;
  email: string | null;
  inn: string;
  kpp: string;
  licenseAuthority: string | null;
  licenseDate: any | null;
  licenseNumber: string | null;
  ogrn: string;
  phone: string | null;
  republicCode: string | null;
  organizationCode: string | null;
  licenseFile: { fileName: string | null; file: string | null } | null;
  printFile: { file: string | null; fileName: string | null } | null;
  mainOkved: { id: number; code: string; name: string };
  additionalOkveds: Array<{ id: number; code: string; name: string }>;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OrganizationQuery = {
  organization: {
    id: number;
    name: string;
    address: string;
    email: string | null;
    inn: string;
    kpp: string;
    licenseAuthority: string | null;
    licenseDate: any | null;
    licenseNumber: string | null;
    ogrn: string;
    phone: string | null;
    republicCode: string | null;
    organizationCode: string | null;
    licenseFile: { fileName: string | null; file: string | null } | null;
    printFile: { file: string | null; fileName: string | null } | null;
    mainOkved: { id: number; code: string; name: string };
    additionalOkveds: Array<{ id: number; code: string; name: string }>;
  };
};

export type OrganizationFragment = {
  id: number;
  name: string;
  address: string;
};

export type OrganizationsQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<OrganizationSortInput> | OrganizationSortInput>;
  where: InputMaybe<OrganizationOutputFilterInput>;
}>;

export type OrganizationsQuery = {
  organizations: {
    totalCount: number;
    items: Array<{ id: number; name: string; address: string }> | null;
  } | null;
};

export type UpdateEmployingOrganizationPayloadFragment = {
  employingOrganization: {
    id: any;
    organizationName: string;
    address: string;
    email: string;
    phoneNumber: string;
    delagateName: string;
    delagateSurname: string;
    delagateFathersName: string;
    ogrn: string;
    inn: string;
    kpp: string;
    hasLoginPassAccess: boolean;
    isAccessBlocked: boolean;
    login: string;
    password: string;
    mainOkved: { id: number; code: string; name: string } | null;
    additionalOkveds: Array<{ id: number; code: string; name: string }>;
  } | null;
};

export type UpdateEmployingOrganizationMutationVariables = Exact<{
  input: UpdateEmployingOrganizationInput;
}>;

export type UpdateEmployingOrganizationMutation = {
  updateEmployingOrganization: {
    employingOrganization: {
      id: any;
      organizationName: string;
      address: string;
      email: string;
      phoneNumber: string;
      delagateName: string;
      delagateSurname: string;
      delagateFathersName: string;
      ogrn: string;
      inn: string;
      kpp: string;
      hasLoginPassAccess: boolean;
      isAccessBlocked: boolean;
      login: string;
      password: string;
      mainOkved: { id: number; code: string; name: string } | null;
      additionalOkveds: Array<{ id: number; code: string; name: string }>;
    } | null;
  };
};

export type UpdateOrganizationPayloadFragment = {
  organization: { id: number } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  updateOrganization: { organization: { id: number } | null };
};

export type CheckCspCertQueryVariables = Exact<{
  cspSignature: Scalars["String"];
}>;

export type CheckCspCertQuery = { checkCspCert: boolean };

export type CreateCspPayloadFragment = {
  cspCertificate: { userId: any; expires: any } | null;
  errors: Array<
    { message: string } | { message: string } | { message: string }
  > | null;
};

export type CreateCspMutationVariables = Exact<{
  cspInput: CreateCspInput;
}>;

export type CreateCspMutation = {
  createCsp: {
    cspCertificate: { userId: any; expires: any } | null;
    errors: Array<
      { message: string } | { message: string } | { message: string }
    > | null;
  };
};

export type EmployingOrganizationProfileViewFragment = {
  id: any;
  organizationName: string;
  address: string;
  phoneNumber: string;
  email: string;
  delagateName: string;
  delagateSurname: string;
  delagateFathersName: string | null;
  ogrn: string;
  inn: string;
  kpp: string;
  mainOkved: { id: number; code: string; name: string };
  additionalOkveds: Array<{ id: number; code: string; name: string }>;
};

export type EmployingOrganizationProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmployingOrganizationProfileQuery = {
  employingOrganizationProfile: {
    id: any;
    organizationName: string;
    address: string;
    phoneNumber: string;
    email: string;
    delagateName: string;
    delagateSurname: string;
    delagateFathersName: string | null;
    ogrn: string;
    inn: string;
    kpp: string;
    mainOkved: { id: number; code: string; name: string };
    additionalOkveds: Array<{ id: number; code: string; name: string }>;
  };
};

export type CspPayloadFragment = {
  userId: any;
  expires: any;
  thumbprint: { value: string };
};

export type GetUserCspQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCspQuery = {
  actualUserCsp: { userId: any; expires: any; thumbprint: { value: string } };
};

export type UpdateEmployingOrganizationProfilePayloadFragment = {
  employingOrganizationProfile: { id: any } | null;
  errors: Array<{ message: string }> | null;
};

export type UpdateEmployingOrganizationProfileMutationVariables = Exact<{
  input: UpdateEmployingOrganizationProfileInput;
}>;

export type UpdateEmployingOrganizationProfileMutation = {
  updateEmployingOrganizationProfile: {
    employingOrganizationProfile: { id: any } | null;
    errors: Array<{ message: string }> | null;
  };
};

export type UserProfileViewFragment = {
  name: string;
  surname: string;
  fathersName: string | null;
  sex: Sex;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  position: string | null;
  roleName: string;
  profileName: string;
  organizationName: string;
};

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileQuery = {
  userProfile: {
    name: string;
    surname: string;
    fathersName: string | null;
    sex: Sex;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    position: string | null;
    roleName: string;
    profileName: string;
    organizationName: string;
  };
};

export type ReportViewFragment = {
  id: number;
  name: string;
  doctorFio: string;
  doctorPosition: string;
  patientFio: string;
  patientBirthdate: any;
  patientSnils: string;
  responseDate: Array<any>;
  number: string;
  isActual: Array<boolean>;
  documents: Array<{
    id: number;
    name: string;
    doctorFio: string | null;
    doctorPosition: string | null;
    responseDate: any | null;
    number: string | null;
    isActual: boolean;
    patientFio: { fio: string } | null;
    patientBirthdate: { birthdate: any } | null;
    patientSnils: { snils: string } | null;
    conclusionFile: { fileName: string | null; file: string | null } | null;
  }>;
};

export type ReportsQueryVariables = Exact<{
  roleName: Scalars["String"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<ReportSortInput> | ReportSortInput>;
  where: InputMaybe<ReportOutputFilterInput>;
}>;

export type ReportsQuery = {
  reports: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      doctorFio: string;
      doctorPosition: string;
      patientFio: string;
      patientBirthdate: any;
      patientSnils: string;
      responseDate: Array<any>;
      number: string;
      isActual: Array<boolean>;
      documents: Array<{
        id: number;
        name: string;
        doctorFio: string | null;
        doctorPosition: string | null;
        responseDate: any | null;
        number: string | null;
        isActual: boolean;
        patientFio: { fio: string } | null;
        patientBirthdate: { birthdate: any } | null;
        patientSnils: { snils: string } | null;
        conclusionFile: { fileName: string | null; file: string | null } | null;
      }>;
    }> | null;
  } | null;
};

export type CreateUserPayloadFragment = {
  user: {
    id: any;
    name: string;
    surname: string;
    fathersName: string | null;
    sex: Sex;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    roleName: string;
  } | null;
  errors: Array<{ message: string }> | null;
};

export type CreateUserMutationVariables = Exact<{
  userInput: CreateUserInput;
}>;

export type CreateUserMutation = {
  createUser: {
    user: {
      id: any;
      name: string;
      surname: string;
      fathersName: string | null;
      sex: Sex;
      birthdate: any;
      snils: string;
      phoneNumber: string | null;
      email: string;
      roleName: string;
    } | null;
    errors: Array<{ message: string }> | null;
  };
};

export type DeleteUserPayloadFragment = {
  user: { id: any } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  deleteUser: {
    user: { id: any } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type ProfileViewFragment = { id: number; name: string };

export type ProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilesQuery = {
  profiles: {
    totalCount: number;
    items: Array<{ id: number; name: string }> | null;
  } | null;
};

export type RoleViewFragment = {
  id: string;
  name: string;
  profiles: Array<{ id: number; name: string }> | null;
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  employeeRoles: {
    totalCount: number;
    items: Array<{
      id: string;
      name: string;
      profiles: Array<{ id: number; name: string }> | null;
    }> | null;
  } | null;
};

export type UpdateUserPayloadFragment = {
  user: {
    id: any;
    login: string;
    password: string;
    name: string;
    surname: string;
    fathersName: string | null;
    sex: Sex;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    roleName: string;
    profileIds: Array<number> | null;
    organizationId: number | null;
    hasLoginPassAccess: boolean;
    isAccessBlocked: boolean;
  } | null;
  errors: Array<{ message: string } | { message: string }> | null;
};

export type UpdateUserMutationVariables = Exact<{
  userInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    user: {
      id: any;
      login: string;
      password: string;
      name: string;
      surname: string;
      fathersName: string | null;
      sex: Sex;
      birthdate: any;
      snils: string;
      phoneNumber: string | null;
      email: string;
      roleName: string;
      profileIds: Array<number> | null;
      organizationId: number | null;
      hasLoginPassAccess: boolean;
      isAccessBlocked: boolean;
    } | null;
    errors: Array<{ message: string } | { message: string }> | null;
  };
};

export type UserViewFragment = {
  id: any;
  login: string;
  password: string;
  name: string;
  surname: string;
  fathersName: string | null;
  sex: Sex;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  position: string | null;
  roleName: string;
  profileIds: Array<number> | null;
  organizationId: number | null;
  hasLoginPassAccess: boolean;
  isAccessBlocked: boolean;
  organizationName: string | null;
  profileNames: string | null;
  commissionHeadUserId: any | null;
  profiles: Array<{ id: number; name: string }> | null;
};

export type UserQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type UserQuery = {
  user: {
    id: any;
    login: string;
    password: string;
    name: string;
    surname: string;
    fathersName: string | null;
    sex: Sex;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    position: string | null;
    roleName: string;
    profileIds: Array<number> | null;
    organizationId: number | null;
    hasLoginPassAccess: boolean;
    isAccessBlocked: boolean;
    organizationName: string | null;
    profileNames: string | null;
    commissionHeadUserId: any | null;
    profiles: Array<{ id: number; name: string }> | null;
  };
};

export type UserItemFragment = {
  id: any;
  login: string;
  password: string;
  name: string;
  surname: string;
  fathersName: string | null;
  sex: Sex;
  fio: string | null;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  position: string | null;
  roleName: string;
  profileIds: Array<number> | null;
  profileNames: string | null;
  organizationId: number | null;
  organizationName: string | null;
  hasLoginPassAccess: boolean;
  isAccessBlocked: boolean;
};

export type UsersQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<UserSortInput> | UserSortInput>;
  where: InputMaybe<UserOutputFilterInput>;
}>;

export type UsersQuery = {
  users: {
    totalCount: number;
    items: Array<{
      id: any;
      login: string;
      password: string;
      name: string;
      surname: string;
      fathersName: string | null;
      sex: Sex;
      fio: string | null;
      birthdate: any;
      snils: string;
      phoneNumber: string | null;
      email: string;
      position: string | null;
      roleName: string;
      profileIds: Array<number> | null;
      profileNames: string | null;
      organizationId: number | null;
      organizationName: string | null;
      hasLoginPassAccess: boolean;
      isAccessBlocked: boolean;
    }> | null;
  } | null;
};

export const namedOperations = {
  Query: {
    Okveds: "Okveds",
    OpoWorkTypes: "OpoWorkTypes",
    OrganizationInfos: "OrganizationInfos",
    ActiveAppealTypes: "ActiveAppealTypes",
    Appeal: "Appeal",
    AppealOpo: "AppealOpo",
    AppealOpoAgreement: "AppealOpoAgreement",
    AppealOpoByEmployingOrganization: "AppealOpoByEmployingOrganization",
    AppealOpoClinicalPsychologistAppointment:
      "AppealOpoClinicalPsychologistAppointment",
    AppealOpoCommissionSize: "AppealOpoCommissionSize",
    AppealOpos: "AppealOpos",
    AppealTypes: "AppealTypes",
    Appeals: "Appeals",
    AppealsByAdmin: "AppealsByAdmin",
    ConclusionAppeal: "ConclusionAppeal",
    Diagnoses: "Diagnoses",
    GetConclusionFileHistories: "GetConclusionFileHistories",
    GetClientsHistoryAsync: "GetClientsHistoryAsync",
    Client: "Client",
    Clients: "Clients",
    Patients: "Patients",
    MultipleAppealOpoFromTemplate: "MultipleAppealOpoFromTemplate",
    MultipleAppealOpoTemplates: "MultipleAppealOpoTemplates",
    EmployingOrganization: "EmployingOrganization",
    EmployingOrganizations: "EmployingOrganizations",
    Organization: "Organization",
    Organizations: "Organizations",
    CheckCspCert: "CheckCspCert",
    EmployingOrganizationProfile: "EmployingOrganizationProfile",
    GetUserCsp: "GetUserCsp",
    UserProfile: "UserProfile",
    Reports: "Reports",
    Profiles: "Profiles",
    Roles: "Roles",
    User: "User",
    Users: "Users",
  },
  Mutation: {
    CreateAppeal: "CreateAppeal",
    CreateConclusion: "CreateConclusion",
    CreateConclusionForOpo: "CreateConclusionForOpo",
    CreateTherapistConclusion: "CreateTherapistConclusion",
    deleteAppealOpo: "deleteAppealOpo",
    DeleteConclusion: "DeleteConclusion",
    DeleteTherapistConclusion: "DeleteTherapistConclusion",
    LoadConclusion: "LoadConclusion",
    LoadTherapistConclusion: "LoadTherapistConclusion",
    MakeClinicalPsychologistAppointment: "MakeClinicalPsychologistAppointment",
    SetCommissionSize: "SetCommissionSize",
    SignAppealOpoAgreement: "SignAppealOpoAgreement",
    createAppealOpo: "createAppealOpo",
    createMultipleAppealOpos: "createMultipleAppealOpos",
    createMultipleAppealOposFromFile: "createMultipleAppealOposFromFile",
    createTemplateForMultipleAppealOpos: "createTemplateForMultipleAppealOpos",
    deleteTemplateForMultipleAppealOpos: "deleteTemplateForMultipleAppealOpos",
    updateTemplateForMultipleAppealOpos: "updateTemplateForMultipleAppealOpos",
    createEmployingOrganization: "createEmployingOrganization",
    createOrganization: "createOrganization",
    updateEmployingOrganization: "updateEmployingOrganization",
    UpdateOrganization: "UpdateOrganization",
    CreateCsp: "CreateCsp",
    UpdateEmployingOrganizationProfile: "UpdateEmployingOrganizationProfile",
    CreateUser: "CreateUser",
    DeleteUser: "DeleteUser",
    UpdateUser: "UpdateUser",
  },
  Fragment: {
    OkvedView: "OkvedView",
    OpoWorkTypeView: "OpoWorkTypeView",
    OrganizationInfoView: "OrganizationInfoView",
    AppealTypeItem: "AppealTypeItem",
    AppealView: "AppealView",
    AppealQueryFragment: "AppealQueryFragment",
    AppealOpoAgreementView: "AppealOpoAgreementView",
    AppealByEmployingOrganizationView: "AppealByEmployingOrganizationView",
    AppealOpoClinicalPsychologistAppointment:
      "AppealOpoClinicalPsychologistAppointment",
    AppealOpoCommissionSize: "AppealOpoCommissionSize",
    AppealOpoView: "AppealOpoView",
    AppealByAdminView: "AppealByAdminView",
    ConclusionAppealView: "ConclusionAppealView",
    CreateAppealsPayload: "CreateAppealsPayload",
    CreateConclusionPayload: "CreateConclusionPayload",
    CreateConclusionForOpoPayload: "CreateConclusionForOpoPayload",
    CreateTherapistConclusionPayload: "CreateTherapistConclusionPayload",
    DeleteAppealOpoPayload: "DeleteAppealOpoPayload",
    DeleteConclusionPayload: "DeleteConclusionPayload",
    DeleteTherapistConclusionPayload: "DeleteTherapistConclusionPayload",
    DiagnosisView: "DiagnosisView",
    LoadConclusionPayload: "LoadConclusionPayload",
    LoadTherapistConclusionPayload: "LoadTherapistConclusionPayload",
    MakeClinicalPsychologistAppointmentPayload:
      "MakeClinicalPsychologistAppointmentPayload",
    SetCommissionSizePayload: "SetCommissionSizePayload",
    SignAppealOpoAgreementPayload: "SignAppealOpoAgreementPayload",
    ConclusionFileHistoryItem: "ConclusionFileHistoryItem",
    HistoryItem: "HistoryItem",
    CreateAppealOpoPayload: "CreateAppealOpoPayload",
    ClientView: "ClientView",
    ClientItem: "ClientItem",
    PatientItem: "PatientItem",
    CreateMultipleAppealOpoPayload: "CreateMultipleAppealOpoPayload",
    CreateMultipleAppealOposFromFilePayload:
      "CreateMultipleAppealOposFromFilePayload",
    MultipleAppealOpoFromTemplateItem: "MultipleAppealOpoFromTemplateItem",
    CreateTemplateForMultipleAppealOposPayload:
      "CreateTemplateForMultipleAppealOposPayload",
    DeleteTemplateForMultipleAppealOposPayload:
      "DeleteTemplateForMultipleAppealOposPayload",
    UpdateTemplateForMultipleAppealOposPayload:
      "UpdateTemplateForMultipleAppealOposPayload",
    MultipleAppealOpoTemplate: "MultipleAppealOpoTemplate",
    CreateEmployingOrganizationPayload: "CreateEmployingOrganizationPayload",
    CreateOrganizationSuccess: "CreateOrganizationSuccess",
    CreateOrganizationFailure: "CreateOrganizationFailure",
    CreateOrganizationPayload: "CreateOrganizationPayload",
    EmployingOrganizationView: "EmployingOrganizationView",
    EmployingOrganization: "EmployingOrganization",
    OrganizationView: "OrganizationView",
    Organization: "Organization",
    UpdateEmployingOrganizationPayload: "UpdateEmployingOrganizationPayload",
    UpdateOrganizationPayload: "UpdateOrganizationPayload",
    CreateCspPayload: "CreateCspPayload",
    EmployingOrganizationProfileView: "EmployingOrganizationProfileView",
    CspPayload: "CspPayload",
    UpdateEmployingOrganizationProfilePayload:
      "UpdateEmployingOrganizationProfilePayload",
    UserProfileView: "UserProfileView",
    ReportView: "ReportView",
    CreateUserPayload: "CreateUserPayload",
    DeleteUserPayload: "DeleteUserPayload",
    ProfileView: "ProfileView",
    RoleView: "RoleView",
    UpdateUserPayload: "UpdateUserPayload",
    UserView: "UserView",
    UserItem: "UserItem",
  },
};
